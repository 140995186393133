html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  --scrollbarWidth: 0px;
}

html.no-js .loadable {
  opacity: 1 !important;
}

body {
  position: relative;
  width: 100%;
  min-height: 100svh;
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.7rem;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: monospace;
  background: #222;
  color: white;

  overflow: hidden;
  touch-action: pan-x pan-y;
}

html,
body {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
address,
div {
  font-style: normal;
}

p {
  padding: 0;
  margin: 0;
  font-weight: normal;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  font: inherit;
  text-decoration: none;
}

blockquote {
  font-weight: normal;
}

cite {
  font-style: normal;
}

strong,
strong em,
strong del {
  font-weight: 700;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

::selection {
  background-color: rgb(var(--accent-primary));
  color: rgb(var(--text-inv-primary));
  text-shadow: 1px 1px rgba(var(--accent-quaternary));
}
